import { useState } from "react";
import marcozciuffini from "../../../assets/marcozciuffini.jpeg";

const developers = [
  {
    icon: marcozciuffini,
    username: "marcozciuffini",
    name: "Marco Zak Ciuffini",
    description: `A full stack developer with a focus on front end and specializing in React Native, can build the whole stack and create REST API based applications for both web and mobile`,
    work: `UK Based, Birmingham, work remotely, 4 days a week`,
    frontend: ["react", "reactNative", "javascript", "typescript", "next"],
    backend: ["laravel", "rails"],
    tools: ["aws", "gcp", "docker"],
    links: [
      {
        icon: "linkedIn",
        link: "https://www.linkedin.com/in/marco-zak-ciuffini-98814414a/",
      },
      {
        icon: "github",
        link: "https://github.com/marcozciuffini",
      },
      {
        icon: "email",
        link: "mailto:marcozciuffini@gmail.com",
      },
    ],
  },
];

const useAbout = () => {
  const [activeDeveloper, setActiveDeveloper] = useState<{
    icon: string;
    username: string;
    name: string;
    description: string;
    work: string;
    frontend: string[];
    backend: string[];
    tools: string[];
    links: {
      icon: string;
      link: string;
    }[];
  }>(developers[0]);

  return {
    developers,
    activeDeveloper,
    setActiveDeveloper,
  };
};

export default useAbout;
