import {
  SiDocker,
  SiGithub,
  SiGooglecloud,
  SiJavascript,
  SiLaravel,
  SiLinkedin,
  SiNextdotjs,
  SiReact,
  SiRubyonrails,
  SiTypescript,
  SiWordpress,
} from "react-icons/si";
import { FaAws, FaEnvelopeOpen } from "react-icons/fa";

export const stackIcons = {
  linkedIn: {
    icon: SiGithub,
    text: "Github",
  },
  github: {
    icon: SiLinkedin,
    text: "LinkedIn",
  },
  email: {
    icon: FaEnvelopeOpen,
    text: "Email",
  },
  react: {
    icon: SiReact,
    text: "React",
  },
  reactNative: {
    icon: SiReact,
    text: "React Native",
  },
  javascript: {
    icon: SiJavascript,
    text: "Javascript",
  },
  typescript: {
    icon: SiTypescript,
    text: "Typescript",
  },
  next: {
    icon: SiNextdotjs,
    text: "Next.js",
  },
  laravel: {
    icon: SiLaravel,
    text: "Laravel",
  },
  rails: {
    icon: SiRubyonrails,
    text: "Rails",
  },
  wordpress: {
    icon: SiWordpress,
    text: "WordPress",
  },
  aws: {
    icon: FaAws,
    text: "AWS",
  },
  gcp: {
    icon: SiGooglecloud,
    text: "GCP",
  },
  docker: {
    icon: SiDocker,
    text: "Docker",
  },
};
